.container-help {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .accordion {
    background-color: #f5f5f5;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
  }

  .active,
  .accordion:hover {
    background-color: #ddd;
  }

  .panel {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
  }

  .contact-form {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
  }

  .contact-form label {
    display: block;
    margin-bottom: 10px;
  }

  .contact-form input,
  .contact-form textarea,
  .contact-form select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }

  .contact-form button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  .phone-numbers {
    margin-top: 20px;
  }