.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.loader-container p {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
}