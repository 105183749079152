.notification-icon {
    fill: white;
    transition: transform 0.3s;
  }
  
  /* Apply a pulse animation when the icon has the "animated" class */
  .notification-icon.animated {
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  .modal-fullscreen {
    width: 90vw !important;
    height: 90vh !important;
    margin: 3 !important;
    max-width: none !important; /* Override default max-width */
  }
  
  .modal-fullscreen .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    max-width: none; /* Important to remove max-width */
  }
  
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0; /* Optional: Remove border */
  }